import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TenantService } from '@/app/services/tenants/tenant.service';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { UserStore } from '@/shared/lib/stores/user.store';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { NotificationStore } from '@/shared/lib/stores/notification.store';
import { AppLoadingNotification } from '@/app/interfaces/app-loading-notification';
import { SideBarComponent } from '@/app/components/layout/sidebar/sidebar.component';
import { PrimeNG } from 'primeng/config';
import { FilterMatchMode } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [CoreModule, CommonModule, RouterOutlet, SideBarComponent, TranslateModule],
})
export class AppComponent implements OnInit {
  userStore = inject(UserStore);
  deploymentStore = inject(DeploymentStore);
  notificationStore = inject(NotificationStore);

  tenantService = inject(TenantService);
  translate = inject(TranslateService);
  primeng = inject(PrimeNG);
  router = inject(Router);

  title = 'cloudOTDR';
  prevAuthStatus = false;
  notificationList = toObservable<AppLoadingNotification | null>(this.notificationStore.notificationData);

  constructor() {
    this.translate.setDefaultLang('en');
    this.loadCoreData();
  }

  ngOnInit() {
    this.primeng.filterMatchModeOptions = {
      text: [FilterMatchMode.STARTS_WITH, FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.ENDS_WITH, FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
      numeric: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN, FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
      date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER],
    };
  }

  loadCoreData() {
    toObservable(this.userStore.isAuthenticated).subscribe((isAuthenticated) => {
      if (isAuthenticated && !this.prevAuthStatus) {
        this.tenantService.initialize();
        this.prevAuthStatus = isAuthenticated;
        this.translate.setDefaultLang(this.userStore.displayLanguage());
      }
    });
  }

  logout() {
    this.userStore.clear();
    this.router.navigate(['/account/login']);
  }
}
