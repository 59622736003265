<div class="flex h-screen overflow-hidden">
  @if (this.userStore.isAuthenticated()){
    <ods-layout-sidebar
      class="shadow-md"
      (onLogout)="logout()"
    />
  }
  <div class="flex flex-1 flex-col overflow-hidden">
    <main class="flex-1 overflow-auto">
      <router-outlet />
    </main>
  </div>
</div>
